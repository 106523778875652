(() => {
  const setThemeOptions = (realm, title) => {
    document.title = title;

    const faviconList = [
      document.querySelector("link[rel='apple-touch-icon']"),
      document.querySelector("link[sizes='32x32']"),
      document.querySelector("link[sizes='16x16']"),
      document.querySelector("link[rel='manifest']"),
    ];
    faviconList.forEach((_i) => {
      // _i.setAttribute('href', _i.getAttribute('href').replace('agrio', realm));
    });

    const logos = [document.querySelector("img[class='logo']")];
    logos.forEach((_i) => {
      // _i.setAttribute("src", _i.getAttribute("src").replace("blank", realm));
    });
  };

  const fetchSync = (url) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, false);
    xhr.send(null);

    const config = JSON.parse(xhr.responseText);
    return config;
  };

  const realmConfig = fetchSync("/assets/config.json");
  //   console.log(landLordConfig);
  setThemeOptions(
    //
    realmConfig.realm,
    realmConfig.title
  );
  window.configuration = {
    ...realmConfig,
  };
})();
